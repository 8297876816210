import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { defaultPageIndex, defaultPageSize, pageIndex, pageSize } from "../components/Utility/constant";

export const usePagination = (
  getPaginationFromParams = true,
  pageIndexKey = pageIndex,
  pageSizeKey = pageSize,
) => {
  const [searchParams, setSearchParams] = useSearchParams();


  const pagination = useMemo(() => {
    if (getPaginationFromParams === false) {
      return {
        perPage: 10000,
        page: 1,
      };
    }

    let index = searchParams.get(pageIndexKey);
    let size = searchParams.get(pageSizeKey);
    return {
      page: index ? Number(index) : defaultPageIndex,
      perPage: size ? Number(size) : defaultPageSize,
    };
  }, [searchParams, getPaginationFromParams, pageIndexKey, pageSizeKey]);

  return pagination;
};