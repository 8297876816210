import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { usePagination } from "../hooks/usePagination";
import { useQuery } from "@tanstack/react-query";

let serverUrl = `${url}/flashSales`;


export const createFlashSales = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/`, obj)
}

export const getAllFlashSales = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/getFlashSales?`+query)
}




const getFlash = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      perPage: String(pagination.perPage),
      page: String(pagination.page),
      ...searchObj,
    }).toString();
    return axiosApiInstance.get(`${serverUrl}/getFlashSales?${query}`
    );
  };
  
  export const useFlashSale = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = true
  ) => {
    const pagination = usePagination(getPaginationFromParams);
  
    return useQuery({
      queryKey: ["flsahSale", pagination, searchObj],
      queryFn: () => getFlash(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };

export const getAllFlashSalesbyUserId = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getFlashSalesByUserId/${id}`)
}

export const getFlashSalebyId = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}

export const updateFlashSalebyId = async (id, obj) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, obj)
}

export const deleteFlashSalebyId = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}



