import axios from "axios";
import { url } from "./url.service";
import usePagination from "@mui/material/usePagination/usePagination";
import { useQuery } from "@tanstack/react-query";



export const getCountriesApi = async (query) => {
    return axios.get(`${url}/country?${query}`)
}


const getCountry = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      pageIndex: String(pagination.pageIndex),
      pageSize: String(pagination.pageSize),
      ...searchObj,
    }).toString();
    return axios.get(`${url}/country?${query}`
    );
  };
  
  export const useCountry = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = true
  ) => {
    const pagination = usePagination(getPaginationFromParams);
    return useQuery({
      queryKey: ["countries", pagination, searchObj],
      queryFn: () => getCountry(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };

export const getStateByCountryApi = async (query) => {
    return axios.get(`${url}/state?${query}`)
}


const getState = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      pageIndex: String(pagination.pageIndex),
      pageSize: String(pagination.pageSize),
      ...searchObj,
    }).toString();
    return axios.get(`${url}/state?${query}`
    );
  };
  
  export const useStates = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = true
  ) => {
    const pagination = usePagination(getPaginationFromParams);
    return useQuery({
      queryKey: ["states", pagination, searchObj],
      queryFn: () => getState(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };


export const getCityByStateApi = async (query) => {
    return axios.get(`${url}/city?${query}`)
}



const getCity = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      pageIndex: String(pagination.pageIndex),
      pageSize: String(pagination.pageSize),
      ...searchObj,
    }).toString();
    return axios.get(`${url}/city?${query}`
    );
  };
  
  export const useCities = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = false
  ) => {
    const pagination = usePagination(getPaginationFromParams);
    return useQuery({
      queryKey: ["cities", pagination, searchObj],
      queryFn: () => getCity(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };


