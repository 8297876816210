import React, { Suspense } from "react";
import Banner from "./HomeComponents/Banner";
import CategoriesSlider from "./HomeComponents/CategoriesSlider";
const NewArrival = React.lazy(() => import('./HomeComponents/NewArrival/NewArrival'))
const TopProfile = React.lazy(() => import('./HomeComponents/TopProfile'))
const FlashSale = React.lazy(() => import('./HomeComponents/FlashSale'))
const TopProduct = React.lazy(() => import('./HomeComponents/TopProduct'))
const RemainingSection = React.lazy(() => import('./HomeComponents/RemainingSection'))
const ReelSection = React.lazy(() => import('./HomeComponents/ReelSection'))
function Index() {
  return (
    <main>
      <Banner />
        <Suspense fallback="Loading..." >
           <CategoriesSlider />
        </Suspense>

        <Suspense fallback="Loading..." >
           <NewArrival />
        </Suspense>


       {/* <Suspense fallback="Loading..." >
           <ReelSection />
        </Suspense>
*/}
        <Suspense fallback="Loading..." >
           <TopProfile />
        </Suspense>


        <Suspense fallback="Loading..." >
           <FlashSale />
        </Suspense>

        <Suspense fallback="Loading..." >
           <TopProduct />
        </Suspense>
        <Suspense fallback="Loading..." >
           <RemainingSection />
        </Suspense>
    </main>
  );
}

export default Index;
