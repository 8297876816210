import React, { useContext, useState,useEffect } from 'react'
import { SignModalContext } from '../../context/SignModalContext';
import Modal from "react-bootstrap/Modal";
import { Link } from 'react-router-dom';
import { images } from "./../Utility/Images";
import { errorToast, successToast } from '../Utility/Toast';
import { sentOtp } from '../../services/User.service';
import { useSelector, useDispatch } from "react-redux";
import { login } from '../../redux/features/auth/authSlice';


const SignModal = () => {
  const { isModalOpen, closeSignModal } = useContext(SignModalContext);
  const dispatch = useDispatch();

  const isAuthorized = useSelector((state) => state.auth.isAuthorized);
  const [otpsent, setotpsent] = useState(false);
  const [mobile, setmobile] = useState("");
  const [otp, setotp] = useState("");


  const handlesendOtp = async () => {
    try {
  
        if (`${mobile}`.length !== 10) {
          errorToast("Please Enter Mobile Number");
          return;
        }
      let obj = {
        phone: mobile,
      };

      // console.log(obj,"gdfgdkfdgfadfdfdkjdhfjkdafhfdkjkskjafhdkjhsjk",)
      // dispatch(otpSend(obj));
      // setotpsent(true);

      let { data: res } = await sentOtp(obj);
      if (res.message) {
        successToast(res.message);
        setotpsent(true);
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };

  useEffect(()=>{
console.log(isAuthorized,"isAuthorizedisAuthorized")
    
    if(isAuthorized  == true){
        closeSignModal()
    }

  },[isAuthorized])

  const resendOtp = async () => {
    try {
      if (`${mobile}` === "") {
        errorToast("Please Enter Mobile Number");
        return;
      }

      let obj = {
        phone: mobile,
        // email: email,
      };

      // dispatch(otpSend(obj));

      let { data: res } = await sentOtp(obj);
      if (res.message) {
        successToast(res.message);
        // setotpsent(true)
      }
    } catch (error) {
      errorToast(error);
      console.log(error);
    }
  };



  const handlesLogin = async () => {
    if (`${otp}` === "") {
      errorToast("Please Enter Otp");
      return;
    }
    let obj = {
      phone: mobile,
      otp,
    };
    dispatch(login(obj));
  };
  if (!isModalOpen) return null;

  return (
    <Modal
    show={isModalOpen}
    centered
    onHide={() => closeSignModal()}
    className="rounded-5"
  >
    <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
      <button
        type="button"
        class="btn-close"
        aria-label="Close"
        onClick={() => closeSignModal()}
      ></button>
      <div>
        <Link to="/" className="navbar-brand">
          <img src={images.logo} alt="" className="main-logo img-fluid" />
        </Link>
      </div>
      <h2 className="heading">LogIn via</h2>
      <form className="form row">
     
          <div className="col-12">
            {otpsent ? (
              <div className="input flex-1">
                <label className="text-start">
                  Enter OTP sent to {mobile}
                </label>
                <input
                  type="text"
                  className="w-100 form-control bg-grey"
                  placeholder="Enter Your OTP"
                  value={otp}
                  onChange={(e) => setotp(e.target.value)}
                />

                <div className="text-end">
                  <div
                    className="resendtp"
                    onClick={() => {
                      resendOtp();
                    }}
                  >
                    Resend OTP
                  </div>
                </div>
              </div>
            ) : (
              <div className="input flex-1">
                <label className="text-start">Phone number</label>
                <input
                  type="number"
                  maxLength={10}
                  className="w-100 form-control bg-grey"
                  placeholder="Enter Your Mobile Number"
                  value={mobile}
                  onChange={(e) => setmobile(e.target.value)}
                />
              </div>
            )}
          </div>

        <div className="col-12">
          {otpsent ? (
            <button
              type="button"
              onClick={() => {
                handlesLogin();
              }}
              className="btn btn-custom text-white yellow-bg py-2 w-100"
            >
              Verfiy
            </button>
          ) : (
            <button
              type="button"
              onClick={() => {
                handlesendOtp();
              }}
              className="btn btn-custom text-white yellow-bg py-2 w-100"
            >
              Submit
            </button>
          )}

          <Link
            to="/Register"
            className="btn btn-custom mt-2 text-white yellow-bg py-2 w-100"
          >
            Register Now
          </Link>
        </div>
      </form>
    </Modal.Body>
  </Modal>
  )
}

export default SignModal