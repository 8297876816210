import React from "react";
import ReactQueryProvider from "./reactQuery";
import RootContextProvider from "../context/RootContext";

export default function RootProvider({ children }) {
  return (
      <ReactQueryProvider>
        <RootContextProvider>{children}</RootContextProvider>
      </ReactQueryProvider>
  );
}