export const ROLES = {
    ADMIN: "ADMIN",
    SUBADMIN: "SUBADMIN",
    USER: "USER",
    APPROVERS: "APPROVERS",
    MANUFACTURER: "MANUFACTURER/IMPORTER",  
    DISTRIBUTOR: "DISTRIBUTOR",
    DEALER: "DEALER",
    RETAILER:"RETAILER",
    CONTRACTOR:"CONTRACTOR",
}   