import usePagination from '@mui/material/usePagination/usePagination';
import { url } from './url.service'
import axios from 'axios'
import { useQuery } from '@tanstack/react-query';

const serverUrl = `${url}/BannerImage`


export const getHomePageBannersApi = async (query) => {
  return axios.get(`${serverUrl}/getbanner?${query}`);
}
const getHomePageBanners = (
  pagination,
  searchObj
) => {
  const query = new URLSearchParams({
    pageIndex: String(pagination.pageIndex),
    pageSize: String(pagination.pageSize),
    ...searchObj,
  }).toString();
  return axios.get(`${serverUrl}/getbanner?${query}`
  );
};

export const useBanners = (
  searchObj = {},
  getPaginationFromParams = true,
  enabled = true
) => {
  const pagination = usePagination(getPaginationFromParams);
  return useQuery({
    queryKey: ["bannners", pagination, searchObj],
    queryFn: () => getHomePageBanners(pagination, searchObj).then((res) => res.data),
    enabled: enabled,
  });
};