import React, { useEffect, useState } from 'react'
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { getHomePageBannersApi, useBanners } from '../../services/homepageBanners.service';
import { errorToast } from '../Utility/Toast';
import { generateImageUrl } from '../../services/url.service';
import { useNavigate } from 'react-router-dom';

const Banner = () => {
    
  const { data: banners } = useBanners('type=WEB');
  const navigate = useNavigate();

  return (
    <section className="">
    <div className="container-fluid">
      <div className="row">
        <Swiper
          modules={[Autoplay]}
          spaceBetween={20}
          slidesPerView={1}
          autoplay={{ disableOnInteraction: false }}
          speed={1500}
          loop
        >
          {banners?.bannerImages &&
            banners?.bannerImages.length > 0 &&
            banners?.bannerImages.map((el, index) => {
              return (
                <SwiperSlide key={index}>
                  <div
                    className="col-12"
                    onClick={() => {
                      // Handle navigation based on the type
                      if (el.type === "productbanner") {
                        navigate(`/ShopDetail/${el.productId.slug}`); // Navigate to /product
                      } else if (el.type === "profilebanner" && el.userId) {
                        navigate(`/Supplier/${el.userId._id}`); // Navigate to /user/:userId
                      } else if (el.type === "Adminbanner" && el.url) {
                        window.open(el.url); // Open the external URL
                      } else {
                        window.open(el.url, "_blank");
                      }
                    }}
                  >
                    <img
                      src={generateImageUrl(el.image)}
                      alt=""
                      className="img sliderimg"
                    />
                  </div>
                </SwiperSlide>
              );
            })}
        </Swiper>
      </div>
    </div>
  </section>
  )
}

export default Banner