import React from 'react'
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from 'react-router-dom';
import { Autoplay, Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import { generateImageUrl } from '../../services/url.service';
import { useCategories } from '../../services/Category.service';
const CategoriesSlider = () => {
  const { data: categories } = useCategories({level:1});
  return (
    <section className="category">
        <Container fluid className="categoriesback p-0 py-4 pb-0">
          <div className="container">
          <div className="title-section  ">
      <h2 className="section-title  text-center">Categories</h2>

    </div>
          </div>
          <Row className='mt-lg-5'>
            <Swiper
              modules={[Autoplay, Navigation]}
              spaceBetween={20}
              slidesPerView={1}
              autoplay={{ disableOnInteraction: false }}
              speed={1500}
              loop
              breakpoints={{
                0: {
                  slidesPerView: 3,
                },
                576: {
                  slidesPerView: 3,
                },
                992: {
                  slidesPerView: 5,
                },
                1200: {
                  slidesPerView: 7,
                },
                1300: {
                  slidesPerView: 7,
                },
                1400: {
                  slidesPerView: 8,
                },
                1700: {
                  slidesPerView: 8,
                },
              }}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
            >
              <div>
                {categories?.data &&
                  categories?.data
                    .map((item, index) => (
                      <SwiperSlide key={index}>
                        <Link to={`Shop?categories=${item._id}`}>
                          <Col className="d-grid text-center align-items-center justify-content-center">
                            <div className="d-grid align-items-center justify-content-center">
                              <img
                                src={generateImageUrl(item.image)}
                                className="img-fluid ctimg "
                                alt={item.name}
                              />
                            </div>
                            <p className="fw-bolder fs-6">{item.name}</p>
                          </Col>
                        </Link>
                      </SwiperSlide>
                    ))}
              </div>
              {/* <div className="swiper-button-next "></div> */}
              {/* <div className="swiper-button-prev"></div> */}
            </Swiper>
          </Row>
        </Container>
      </section>
  )
}

export default CategoriesSlider