import React, { useEffect, useState } from 'react'
import { getAllsubscription } from '../services/Subscription.service'
import { buySubscription } from '../services/UserSubscription.service';
import { errorToast, successToast } from './Utility/Toast'
import { useSelector } from 'react-redux';
import { getAlltopups } from '../services/topup.service';
import { buyTopup } from '../services/UserTopup.service';
import "../assets/css/Topup.css"
import Modal from "react-bootstrap/Modal";

export default function Topup() {
    const [loading, setLoading] = useState(false);
    const [paymentModal, setPaymentModal] = useState(false);
    const [topupArr, setSubscriptionArr] = useState([]);
    const [subscriptionObj, setSubscriptionObj] = useState();

    let role = useSelector(state => state.auth.role)

    const handleGetSubscriptions = async () => {
        try {
            let { data: res } = await getAlltopups(`role=${role}`)
            if (res.data) {
                setSubscriptionArr(res.data)
            }
        }
        catch (err) {
            errorToast(err)
        }
    }

    useEffect(() => {
        handleGetSubscriptions()
    }, [])
    const handlePlayment = (subscription) => {
        setSubscriptionObj(subscription)
        setPaymentModal(true)
    }


    const handleBuyTopup = async (subscriptionObj,paymentType) => {
        try {
            let obj = {
                ...subscriptionObj,
                paymentType
            }
            let { data: res } = await buyTopup(obj)
            setLoading(true)
            if (res.success) {

                // successToast(res.message);
                if (paymentType == 'phonepay') {
                    if (res?.data && res?.data.instrumentResponse) {
                        let instrumentResponse = res?.data.instrumentResponse;
                        if (instrumentResponse?.redirectInfo) {
                            window.location.href = instrumentResponse?.redirectInfo.url;
                            return 0;
                        }
                        setLoading(false)

                    } else {
                        setLoading(false)

                        errorToast(
                            "`Phonepe is not working.Please Try Some another Payment Method"
                        );
                    }

                } else if (paymentType == 'justpay') {
                    if (res && res.payment_links) {
                        let payment_links = res.payment_links;

                        // Redirect to payment link if available
                        if (payment_links.web) {
                            setLoading(false); // Hide loading before redirect
                            window.location.href = payment_links.web;
                            return 0;
                        }
                        setLoading(false)

                    } else {
                        setLoading(false)

                        errorToast(
                            "Payment gateway is not working. Please try another payment method."
                        );
                    }
                }
                setLoading(false)

                return 0;
            }
        }
        catch (err) {
            console.log(err)
            errorToast(err)
        }
    }

    return (
        <div className='container-fluid subscription-container topup-subscription-container'>
            <div className="container">
                <div className="subsctiption-heading">
                    Our topup plans
                </div>
                <div className="subscription-description">
                    Buy our topup to get a steady flow of leads for your business and take your business to the next level.
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="row">
                            {
                                topupArr && topupArr.length > 0 && topupArr.map((el, index) => {
                                    return (
                                        <div key={index} className="subscription-card">
                                            <div className="subscription-card-heading">{el?.name}</div>
                                            <div className="subscription-card-price">₹ {el?.price}</div>
                                            {
                                                <div className="subscription-card-days">No Validity</div>
                                            }
                                            <div className="subscription-card-description mb-3">{el?.description}</div>
                                            <div className="subscription-card-days">{el?.numberOfSales != 0 ? `${el?.numberOfSales} Flash sales` : "No Flash sales"}</div>
                                            {

                                                <div className="subscription-card-description mt-0">For {el?.saleDays > 1 ? `${el?.saleDays} Days` : `${el?.saleDays} Day`}</div>
                                            }
                                            <div className="subscription-card-days">{el?.numberOfAdvertisement != 0 ? `${el?.numberOfAdvertisement} Advertisements` : "No Advertisements"}</div>
                                            {
                                                el?.advertisementDays > 0 &&
                                                <div className="subscription-card-description mt-0">For {el?.advertisementDays > 1 ? `${el?.advertisementDays} Days` : `${el?.advertisementDays} Day`}</div>
                                            }
                                            <div className="subscription-card-days">{el?.numberOfBannerImages != 0 ? `${el?.numberOfBannerImages} BannerImages` : "No BannerImages"}</div>

                                            {

                                                <div className="subscription-card-description mt-0">For {el?.bannerimagesDays > 1 ? `${el?.bannerimagesDays} Days` : `${el?.bannerimagesDays} Day`}</div>
                                            }
                                            {
                                                el.messageArr && el.messageArr.length < 0 && (
                                                    <ul className="subscription-card-message-list pb-5 mb-3">
                                                        {
                                                            el.messageArr.map((ele, indexX) => (
                                                                <li key={indexX}>{ele?.message}</li>
                                                            ))
                                                        }
                                                    </ul>
                                                )
                                            }

                                            <button className="yellow-bg btn text-white subsctiption-card-button" onClick={() => handlePlayment(el)}>
                                                Buy Now
                                            </button>
                                        </div>
                                    )
                                })
                            }
                        </div>
                    </div>
                </div>
            </div>
            <Modal
                show={paymentModal}
                centered
                onHide={() => setPaymentModal(true)}
                className="rounded-5"
            >
                <Modal.Body className="sign-in-modal custom-modal subscription-card-container rounded-5">
                    <button
                        type="button"
                        class="btn-close"
                        aria-label="Close"
                        onClick={() => setPaymentModal(false)}
                    ></button>

                    <h2 className="heading">Pay With</h2>
                    <form className="form row">
                        {
                            loading ? 'Please Wait...' : (<div className="col-12">
                                <button
                                    type="button"
                                    onClick={() => handleBuyTopup(subscriptionObj, 'phonepay')}
                                    className="btn btn-custom text-white yellow-bg py-2 w-100"
                                >
                                    PhonePay
                                </button>
                                <button
                                    type="button"
                                    onClick={() => handleBuyTopup(subscriptionObj, 'justpay')}

                                    className="btn btn-custom text-white yellow-bg py-2 w-100 mt-3"
                                >
                                    JustPay
                                </button>
                            </div>)
                        }



                    </form>
                </Modal.Body>
            </Modal>
        </div>
    )
}
