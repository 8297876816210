import axios from "axios";
import { axiosApiInstance } from "../App";
import { url } from "./url.service";
import { useQuery } from "@tanstack/react-query";
import { usePagination } from "../hooks/usePagination";

let serverUrl = `${url}/advertisement`;


export const AddAdvertisement = async (obj) => {
    return axiosApiInstance.post(`${serverUrl}/`, obj)
}

export const getAllAdvertisements = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/?userId=${query}`)
}
export const getForHomepage = async (query) => {
    return axiosApiInstance.get(`${serverUrl}/getForHomepage?${query}`)
}

const getNewArrival = (
    pagination,
    searchObj
  ) => {
    const query = new URLSearchParams({
      pageIndex: String(pagination.pageIndex),
      pageSize: String(pagination.pageSize),
      ...searchObj,
    }).toString();
    return axios.get(`${serverUrl}/getForHomepage/?${query}`)
  };
  
  export const useNewArrival = (
    searchObj = {},
    getPaginationFromParams = true,
    enabled = true
  ) => {
    const pagination = usePagination(getPaginationFromParams);
    return useQuery({
      queryKey: ["newArrvial", pagination, searchObj],
      queryFn: () => getNewArrival(pagination, searchObj).then((res) => res.data),
      enabled: enabled,
    });
  };



export const getAdvertisementById = async (id) => {
    return axiosApiInstance.get(`${serverUrl}/getById/${id}`)
}


export const deleteAdvertisement = async (id) => {
    return axiosApiInstance.delete(`${serverUrl}/deleteById/${id}`)
}


export const updateAdvertisementApi = (formData, id) => {
    return axiosApiInstance.patch(`${serverUrl}/updateById/${id}`, formData)
}


// https://api.plywoodbazar.com/v2/uploads/1727869169525.jpeg
// https://api.plywoodbazar.com/v2/uploads/1727428682804.jpeg
